import React from 'react';

const createOutput = (
botResponse: string,
roomType: string
): JSX.Element => {
let newBotResponse = botResponse.replace(/\.\s/g, ".\n");
newBotResponse = newBotResponse.replace(/\?\s/g, "?\n");
const newBotResponseLines = newBotResponse.split('\n');

return (
<div>
<h2>{roomType === 'transcript' ? "변환 결과" : "번역 결과"}</h2>
<br/>
{newBotResponseLines.map((line, index) => (
<React.Fragment key={index}>
{line}
<br/>
</React.Fragment>
))}
<br/><br/>
</div>
);
};

export default createOutput;
