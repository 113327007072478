import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createOutput from './createoutput';

interface TransDisplayProps {
roomType: string;
model: string;
setFile: React.Dispatch<React.SetStateAction<File | null>>;
source: File | null;
setSource: React.Dispatch<React.SetStateAction<File | null>>;
output: React.ReactNode;
setOutput: React.Dispatch<React.SetStateAction<React.ReactNode>>;
saveResult: string;
setSaveResult: React.Dispatch<React.SetStateAction<string>>;
setDeleteResults: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
transDataLoad: boolean;
apiKey: string;
akNo: number;
}

const TransDisplay: FC<TransDisplayProps> = ({
roomType,
model,
setFile,
source,
setSource,
output,
setOutput,
saveResult,
setSaveResult,
setDeleteResults,
setIsSending,
transDataLoad,
apiKey,
akNo
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setOutput('잠시만 기다려 주세요.');
setDeleteResults('');
setSaveResult('');

const formData = new FormData();
if (source) {
formData.append('file', source);
}
formData.append('model', model);
let botResponse = '';

try {
const endpoint = roomType === 'transcript' ? 'audio/transcriptions' : 'audio/translations';
const response = await axios.post(`https://api.openai.com/v1/${endpoint}`, formData, {
headers: {
'Authorization': `Bearer ${apiKey}`,
'Content-Type': 'multipart/form-data',
},
});

botResponse = response.data.text;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setOutput('');
resetState();
setIsSending(false);
return;
}

const userInput = source ? source.name : '';

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveTrans`, {
userInput: userInput,
botResponse: botResponse,
roomType: roomType,
akNo: akNo
}, {
headers: {
'Content-Type': 'application/json'
}
});
const result = saveResponse.data;
setSaveResult(result);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setOutput('');
resetState();
setIsSending(false);
return;
}

setOutput(createOutput(botResponse, roomType));
resetState();
};

const resetState = () => {
setFile(null);
setSource(null);
setIsSending(false);
}

if (source && !transDataLoad) {
SendRequest();
}
}, [roomType, model, setFile, source, setSource, setOutput, setSaveResult, setDeleteResults, setIsSending, transDataLoad, apiKey, akNo, setError]);

return (
<>
<div aria-live="polite">
{output}
</div>
{error && (
<div className="error" aria-live="polite">
{error}
</div>
)}
{saveResult}
</>
);
};

export default TransDisplay;
