import React, { useState } from 'react';

import { searchHandler } from '../common/keydownhandler';

import { ChatAudioData } from '../../@types/data';
import result from '../../assets/sound/result.wav';
import OnePagePrior from '../../assets/sound/OnePagePrior.wav';
import OnePageNext from '../../assets/sound/OnePageNext.wav';

interface ChatDataDisplayProps {
roomType: string;
totalCount: number;
currentPage: number;
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
totalPage: number;
setTotalPage: React.Dispatch<React.SetStateAction<number>>;
userQuery: string;
setUserQuery: React.Dispatch<React.SetStateAction<string>>;
setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
chatAudioData: ChatAudioData[];
setDeleteTarget: React.Dispatch<React.SetStateAction<number | null>>;
isSending: boolean;
setChatDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatDataDisplay: React.FC<ChatDataDisplayProps> = ({
roomType,
totalCount,
currentPage,
setCurrentPage,
totalPage,
setTotalPage,
userQuery,
setUserQuery,
setSearchQuery,
chatAudioData,
setDeleteTarget,
isSending,
setChatDataLoad
}) => {
const [visibleAnswerId, setVisibleAnswerId] = useState<number | null>(null);

const searchButtonHandler = () => {
setCurrentPage(1);
setSearchQuery(userQuery);
const audio = new Audio(result);
audio.play();
};

const previousPageButtonHandler = () => {
setCurrentPage(currentPage - 1)
const audio = new Audio(OnePagePrior);
audio.play();
};

const nextPageButtonHandler = () => {
setCurrentPage(currentPage + 1)
const audio = new Audio(OnePageNext);
audio.play();
};

let currentAudio: HTMLAudioElement | null = null;

const playAudioHandler = (audioSrc: string) => {
if (currentAudio) {
currentAudio.pause();
currentAudio.currentTime = 0;
currentAudio = null;
} else {
const audioElement = new Audio(audioSrc);

audioElement.addEventListener('ended', () => {
currentAudio = null;
});

try {
audioElement.play().then(() => {
console.log("재생 시작");
currentAudio = audioElement;
}).catch((error) => {
console.error("재생 실패:", error);
});
} catch(e) {
console.error("브라우저 호환성 문제:", e);
}
}
};

const playMp3AudioHandler = (audio: string) => {
const audioSrc = `data:audio/mp3;base64,${audio}`;
playAudioHandler(audioSrc);
};

const playWavAudioHandler = (audio: string) => {
const audioSrc = `data:audio/wav;base64,${audio}`;
playAudioHandler(audioSrc);
};

const deleteDataHandler = (id: number) => {
setDeleteTarget(id);
};

const toggleAnswerVisibility = (id: number) => {
if (visibleAnswerId === id) {
setVisibleAnswerId(null);
} else {
setVisibleAnswerId(id);
}
};

return (
<>
{chatAudioData.length > 0 && (
<>
{setTotalPage(Math.ceil(totalCount / 10))}
</>
)}

{chatAudioData.length === 0 && (
<>
{setTotalPage(1)}
</>
)}

<p>검색어를 입력하세요.</p>
<p>검색어</p>
<input
type="search"
placeholder="검색어"
value={userQuery}
onChange={e => setUserQuery(e.target.value)}
onKeyDown={searchHandler}
required/>
<br/><br/>
<button id="search-button" onClick={searchButtonHandler}>검색</button>

<h1>기존 채팅 기록</h1>
<br />
<p>총 {totalCount} 건</p>
<br />

{chatAudioData.map((item) => (
<React.Fragment key={item.id}>
<h2 onClick={() => toggleAnswerVisibility(item.id)} style={{ cursor: 'pointer' }}>
<pre>
{item.requestTextLines.map((line, index) => (
<React.Fragment key={index}>
{line}
<br />
</React.Fragment>
))}
</pre>
</h2>
<br />

{(item.requestAudio.length !== 0) && (item.requestAudioFormat === 'mp3') && (
<>
<button onClick={() => playMp3AudioHandler(item.requestAudio)}>사용자 요청 오디오 재생</button>
<br />
</>
)}

{(item.requestAudio.length !== 0) && (item.requestAudioFormat === 'wav') && (
<>
<button onClick={() => playWavAudioHandler(item.requestAudio)}>사용자 요청 오디오 재생</button>
<br />
</>
)}

{visibleAnswerId === item.id && (
<>
<pre>
{item.answerTextLines.map((line, index) => (
<React.Fragment key={`answerTextLine-${index}`}>
{roomType === 'businessmail' ? (
<span dangerouslySetInnerHTML={{ __html: line.trimEnd().replace(/(['"])/g, '$1') }} />
) : (
<span>{line.trimEnd().replace(/(['"])/g, '$1')}</span>
)}
</React.Fragment>
))}
</pre>

<button onClick={() => playWavAudioHandler(item.answerAudio)}>챗봇 답변 오디오 재생</button>
</>
)}

<br /><br />
<button onClick={() => deleteDataHandler(item.id)}>삭제</button>

<br /><br /><br /><br />
</React.Fragment>
))}
<p>{currentPage}/{totalPage} 페이지</p>
<br/>
<button
onClick={() => previousPageButtonHandler()}
disabled={currentPage === 1}>
이전
</button>
<br/><br/>

<button
onClick={() => nextPageButtonHandler()}
disabled={currentPage === totalPage}>
다음
</button>
<br/><br/>

{setChatDataLoad(false)}
</>
);
};

export default ChatDataDisplay;
